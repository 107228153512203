export function generateCoordKey(x, y, z) {
  let data = Array.isArray(x) ? x : [x, y, z];
  return data.join('|');
}


export function decodeCoordKey(key) {
  return key.split('|').map(x => parseInt(x));
}


export function hexToRgb(bigInt) {
  return [(bigInt >> 16) & 255, (bigInt >> 8) & 255, (bigInt & 255)];
}


export function isPastDate(date) {
  const target = new Date(date);
  const today = new Date();

  const targetYear = target.getFullYear();
  const targetMonth = String(target.getMonth() + 1).padStart(2, '0');
  const targetDate = String(target.getDate()).padStart(2, '0');

  const todayYear = today.getUTCFullYear();
  const todayMonth = String(today.getUTCMonth() + 1).padStart(2, '0');
  const todayDate = String(today.getUTCDate()).padStart(2, '0');

  const targetNumber = parseInt(`${targetYear}${targetMonth}${targetDate}`);
  const todayNumber = parseInt(`${todayYear}${todayMonth}${todayDate}`);

  return targetNumber < todayNumber
}


export function rgbToHex(r, g, b) {
  let s = '0x' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return parseInt(s);
}


export function randomInt(n) {
  return Math.floor(Math.random() * n);
}


export function sum(array) {
  return array.reduce((a, b) => a + b, 0);
}


export function voxelIndex(x, y, z, w, h, itemSize) {
  return (x + (y * w) + (z * w * h)) * itemSize;
}


export function dateString(date) {
  let year = date.getFullYear();
  let month = 1 + date.getMonth();
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = ('0' + month).slice(-2);
  day = ('0' + day).slice(-2);
  hour = ('0' + hour).slice(-2);
  minute = ('0' + minute).slice(-2);
  second = ('0' + second).slice(-2);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}


export function zeros3d(x, y, z) {
  return Array.from(new Array(z), () => {
    return Array.from(new Array(y), () => new Array(x).fill(0))
  });
}
