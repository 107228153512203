import React from 'react';
import * as THREE from 'three';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { MobileView, isMobile, isBrowser } from "react-device-detect";
import { API, Auth } from 'aws-amplify';

import Avatars from '../assets/js/avatar';
import BlockEditModes from '../assets/js/blockEditModes';
import ChunkManager from '../assets/js/chunkManager';
import GameControls from '../assets/js/gameControls';
import Items from '../assets/js/items';
import MessageTypes from '../assets/js/messageTypes';
import ObjectTypes from '../assets/js/objectTypes';
import SwitchModes from '../assets/js/switchModes';
import Sound from '../assets/js/sound';
import Joysticks from '../assets/js/joysticks';
import { dateString } from '../assets/js/utils';

import Instructions from './instructions';
import MousePointer from './mousePointer';
import { BlockEditButtons, BlockEditModeButtons, ModeButtons } from './buttons';
import { ColorPalette } from './colorpalette';


class Scene extends React.Component {

  constructor(props) {

    super(props);

    const today = new Date();
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, '0');
    const day = String(today.getUTCDate()).padStart(2, '0');

    this.state = {
      pointerLock: false,
      mouseDown: -1,
      renderDistance: isMobile ? 3 : process.env.REACT_APP_RENDER_DISTANCE || 6,
      loadingCount: 0,
      loadingProgress: 0,
      numClients: 0,
      title: 'MIRROR WORLD',
      lastModified: '',
      credits: [],
      historyDate: `${year}-${month}-${day}`,
      difference: SwitchModes.OFF,
      addedVoxelsCount: 0,
      removedVoxelsCount: 0,
      blockColor: '#ffffff',
      blockEditMode: BlockEditModes.ADD,
      isFlying: false,
      isColorPaletteVisible: false
    };

  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);
    this.worldName = urlParams.get('world') || 'sfc_campus_000050cm_v1';
    this.cache = JSON.parse(localStorage.getItem(this.worldName) || '{}');

    this.initScene();
    this.animate();

    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleWindowResize);
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('contextmenu', this.handleContextMenu);

    this.controls.addEventListener('unlock', this.handlePointerUnlock);
    this.controls.addEventListener('save', this.handleSave);
    this.controls.addEventListener('screenshot', this.handleScreenShot);
    this.controls.addEventListener('exportMinecraft', this.handleExportMinecraft);
    this.controls.addEventListener('exportPLY', this.handleExportPLY);
    this.controls.addEventListener('resetPosition', this.handleResetPosition);
    this.controls.addEventListeners();

  }

  componentWillUnmount() {

    window.cancelAnimationFrame(this.frameId);
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('orientationchange', this.handleWindowResize);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('contextmenu', this.handleContextMenu);

    this.controls.removeEventListeners();
    this.controls.dispose();

  }

  initScene() {

    const fov = 70;
    const near = 0.1;
    const far = 600;
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.clock = new THREE.Clock();

    this.lastVoxel = new THREE.Vector3();
    this.lastTime = performance.now();

    this.defaultCameraPosition = new THREE.Vector3(0, 1, -32);
    this.defaultCameraTarget = new THREE.Vector3(1, 1, -32);

    // Mouse
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    // Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x181818);
    this.scene.fog = new THREE.Fog(0xbac8c9, near, far);
    // this.scene.add(new THREE.AxesHelper(2));
    this.scene.add(new THREE.HemisphereLight(0xfcfcfc, 0x181818));

    // Lights
    this.directionalLight(0xffffff, 0.5, 0, 3, 2);
    this.directionalLight(0xffffff, 0.2, -2, -1, 0);
    this.directionalLight(0xffffff, 0.2, 2, -1, 0);

    // Sound
    const listener = new THREE.AudioListener();
    this.sound = new Sound(listener);

    // World
    const worldName = this.worldName;
    const renderDistance = this.state.renderDistance;
    const historyDate = this.state.historyDate;

    // WebSocket
    // TODO: reconnect if socket is closed
    this.socket = new WebSocket(`${process.env.REACT_APP_WS_HOST}/${worldName}`);
    this.socket.onmessage = this.handleSocketMessage;
    this.socket.onclose = () => {
      console.log('Oh, websocket is closed');
    }

    // ChunkManager
    this.chunkManager = new ChunkManager(
      worldName, renderDistance, historyDate, this.sound, this.socket
    );

    this.chunkManager.onChunkCreate = this.handleChunkCreate;
    this.chunkManager.onChunkLoad = this.handleChunkLoad;
    this.chunkManager.onVoxelsCountUpdate = this.handleVoxelsCountUpdate;

    this.scene.add(this.chunkManager.objects);
    this.scene.add(this.chunkManager.helpers);

    // Sky
    const sky = new Sky();
    sky.scale.setScalar(10000);
    sky.material.uniforms['sunPosition'].value.set(0.5, 0.5, -0.5);
    this.scene.add(sky);

    // Items
    this.items = new Items();
    this.scene.add(this.items.objects);

    // Avatars
    this.avatars = new Avatars();
    this.scene.add(this.avatars.group);

    // Camera
    this.camera = new THREE.PerspectiveCamera(fov, width / height, near, far);
    this.camera.position.copy(this.defaultCameraPosition);
    this.camera.lookAt(
      this.defaultCameraTarget.x,
      this.defaultCameraTarget.y,
      this.defaultCameraTarget.z
    );
    this.camera.add(listener);

    this.direction = new THREE.Vector3();

    // Controls
    this.controls = new GameControls(this.camera, document.body, this.chunkManager);

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      // preserveDrawingBuffer: true
    });
    // this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);

    // Mobile only functions
    if (isMobile) {
      const joysticks = new Joysticks(this.camera, this.controls, this.onDown);
      joysticks.virtualJoystickRotate(document.getElementById('joystick-rotate'));
      joysticks.virtualJoystickMove(document.getElementById('joystick-move'));
    }

    this.loadDescription(worldName);
    this.loadStartPosition(worldName);
    this.loadVoxelsCount(worldName);

    setInterval(this.updatePosition, 35);

  }

  /**
   * 説明文を読み込む
   */
  loadDescription(worldName) {

    let req = new XMLHttpRequest();
    let url = `${process.env.REACT_APP_WORLD_BASE_URL}/${worldName}.txt`;

    req.open('GET', url);
    req.onload = () => {
      let data = JSON.parse(req.response);
      let date = new Date(data.date);

      this.setState((state, props) => ({
        title: data.title || state.title,
        lastModified: dateString(date),
        credits: data.credits || []
      }));
    };
    req.send();

  }

  /**
   * スタート位置を決める
   */
  loadStartPosition(worldName) {

    const req = new XMLHttpRequest();
    const url = `${process.env.REACT_APP_WORLD_BASE_URL}/${worldName}/start.txt`;

    req.open('GET', url);
    req.onload = () => {
      if (this.cache.camera) {
        // cached position exists
        this.camera.position.set(
          this.cache.camera.position.x,
          this.cache.camera.position.y,
          this.cache.camera.position.z
        );
        this.camera.lookAt(
          this.cache.camera.target.x,
          this.cache.camera.target.y,
          this.cache.camera.target.z
        );
      } else if (req.response) {
        // set world default position
        const data = req.response.split(',');
        const coord = data.map(x => parseFloat(x));

        this.defaultCameraPosition.set(coord[0], coord[1], coord[2]);
        this.defaultCameraTarget.set(coord[3], coord[4], coord[5]);

        this.camera.position.copy(this.defaultCameraPosition);
        this.camera.lookAt(
          this.defaultCameraTarget.x,
          this.defaultCameraTarget.y,
          this.defaultCameraTarget.z
        );
      }
    };
    req.send();

  }

  /**
   * 編集したボクセル数を読み込む
   */
  loadVoxelsCount(worldName) {

    Auth.currentAuthenticatedUser().then(user => {
      API.get('cloudvoxApi', '/user', {
        queryStringParameters: {
          user_id: user.attributes.sub,
          world_name: worldName
        }
      }).then(response => {
        this.addedVoxelsCount = response['added_voxels_count'];
        this.removedVoxelsCount = response['removed_voxels_count']
        this.setState({
          addedVoxelsCount: this.addedVoxelsCount,
          removedVoxelsCount: this.removedVoxelsCount
        });
      });
    }).catch(error => {
      // pass
    });

  }

  /**
   * 編集したボクセル数を保存する
   */
  saveVoxelsCount() {

    Auth.currentAuthenticatedUser().then(user => {
      API.post('cloudvoxApi', '/user', {
        body: {
          user_id: user.attributes.sub,
          world_name: this.worldName,
          added_voxels_count: this.state.addedVoxelsCount,
          removed_voxels_count: this.state.removedVoxelsCount
        }
      });
    }).catch(error => {
      // pass
    });

  }

  /**
   * 直接光
   */
  directionalLight(color, intensity, x, y, z) {

    let light = new THREE.DirectionalLight(color, intensity);
    light.position.set(x, y, z);
    this.scene.add(light);

    // let helper = new THREE.DirectionalLightHelper(light, 1);
    // this.scene.add(helper);

  }

  /**
   * 経過時間
   */
  elapsedTime() {

    return (performance.now() - this.lastTime) / 1000;

  }

  /**
   * ボクセルの自動保存
   */
  autoSave() {

    if (this.elapsedTime() < 60) return;

    Auth.currentAuthenticatedUser().then(user => {
      if (this.chunkManager.unsavedChunksExist()) {
        this.chunkManager.saveChunks().then(result => {
          if (result.success) {
            console.log('Saved!');
            this.saveVoxelsCount();
          }
        });
      }
    }).catch(error => {
      // pass
    });

    this.lastTime = performance.now();

  }

  /**
   * 3Dビューのフレーム更新
   */
  animate = () => {

    const delta = this.clock.getDelta();

    this.frameId = window.requestAnimationFrame(this.animate);
    this.avatars.animate(delta);
    this.controls.update();
    this.chunkManager.update(this.camera);
    this.renderer.render(this.scene, this.camera);
    this.autoSave();

  }

  /**
   * 現在位置を送る
   */
  updatePosition = (action) => {

    if (this.socket.readyState === WebSocket.OPEN && this.socketUUID) {
      // カメラの向き
      this.camera.getWorldDirection(this.direction);

      const data = {
        type: MessageTypes.AVATAR,
        uuid: this.socketUUID,
        position: {
          x: this.camera.position.x,
          y: this.camera.position.y,
          z: this.camera.position.z
        },
        direction: {
          x: this.direction.x,
          y: this.direction.y,
          z: this.direction.z
        }
      };
      if (action) data.action = action;
      this.socket.send(JSON.stringify(data));
    }

  }

  /**
   * WebSocket通信
   */
  handleSocketMessage = (event) => {

    const data = JSON.parse(event.data);

    switch (data.type) {

      case MessageTypes.INIT:
        this.socketUUID = data.uuid;
        break;

      case MessageTypes.VOXEL:
        this.chunkManager.syncVoxels(data);
        break;

      case MessageTypes.ROOM:
        this.handleRoomUpdate(data);
        break;

      case MessageTypes.AVATAR:
        this.handleAvatarUpdate(data);
        break;

      default:
    }

  }

  /**
   * ウィンドウがリサイズされたときの処理
   */
  handleWindowResize = () => {

    const width = window.innerWidth;
    const height = window.innerHeight;

    // Update camera
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(width, height);

  }

  /**
   * マウスボタンもしくはタッチスクリーンが押されたときの処理
   */
  onDown = (event) => {

    // 衝突判定対象のオブジェクトリストを作る
    let chunks = this.chunkManager.getEditableObjects();
    let objects = [...chunks, ...this.items.objects.children];

    // レイを飛ばしてぶつかったオブジェクトを取得
    this.raycaster.setFromCamera(this.mouse, this.camera);
    let intersects = this.raycaster.intersectObjects(objects);

    if (intersects.length > 0) {
      // レイにぶつかったオブジェクトがある場合
      let intersect = intersects[0];
      let object = intersect.object;

      switch (object.userData.type) {

        case ObjectTypes.CHUNK:
          this.chunkManager.mouseEdit(event, intersect);
          this.setState({ blockColor: this.chunkManager.blockColorHex });
          break;

        case ObjectTypes.ITEM:
          this.items.mouseDown(object);
          break;

        default:
      }
    }

    this.updatePosition('Punch');

  }

  /**
   * マウス移動のときの処理
   */
  handleMouseMove = (event) => {

    if (!this.controls.isLocked) return;
    event.preventDefault();

    let objects = this.items.objects.children;
    if (this.state.mouseDown >= 0) {
      const chunks = this.chunkManager.getEditableObjects();
      objects = [...chunks, ...this.items.objects.children];
    }

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(objects);

    if (intersects.length > 0) {
      const intersect = intersects[0];
      const object = intersect.object;

      switch (object.userData.type) {

        case ObjectTypes.CHUNK:
          this.chunkManager.beginDraw(this.state.mouseDown, intersect);
          break;

        case ObjectTypes.ITEM:
          this.items.mouseOver(object);
          break;

        default:
      }
    } else {
      this.items.mouseOut();
    }

  }

  /**
   * マウスボタン押下のときの処理
   */
  handleMouseDown = (event) => {

    if (!this.controls.isLocked) return;

    event.preventDefault();

    this.setState({ mouseDown: event.button });
    this.onDown(event);

  }

  /**
   * マウスボタンを離したときの処理
   */
  handleMouseUp = (event) => {

    if (!this.controls.isLocked) return;
    event.preventDefault();
    this.chunkManager.endDraw(this.state.mouseDown);
    this.setState({mouseDown: -1});

  }

  /**
   * 右クリックイベント
   */
  handleContextMenu = (event) => {

    if (this.controls.isLocked) event.preventDefault();

  }

  /**
   * ワールドに入る
   */
  handlePointerLock = () => {

    this.setState({ pointerLock: true });
    this.controls.enterWorld();

    if (isBrowser) {
      this.controls.lock();
    }

  }

  /**
   * メニューに戻る
   */
  handlePointerUnlock = () => {

    this.setState({ pointerLock: false });
    this.controls.exitWorld();

  }

  /**
   * レンダリングする範囲を変更する
   */
  handleRenderDistanceChange = (value) => {

    this.setState({ renderDistance: parseInt(value) });
    this.chunkManager.horizontalRenderDistance = parseInt(value);

  }

  /**
   * 差分ボクセルの表示・非表示切り替え
   */
  handleDifferenceChange = (event) => {

    const difference = event.target.checked ? SwitchModes.ON : SwitchModes.OFF;
    this.setState({
      difference: difference,
      loadingCount: 0
    });
    this.chunkManager.setDifference(difference);
    this.chunkManager.refresh();

  }

  /**
   * 指定した日付の状態を読み込む
   */
  handleHistoryDateChange = (value) => {

    this.setState({
      historyDate: value,
      loadingCount: 0
    });
    this.chunkManager.setHistoryDate(value);
    this.chunkManager.refresh();

  }

  /**
   * ブロックの色を変更
   */
  handleBlockColorChange = (event) => {

    const hex = event.target.value;
    this.updateBlockColor(hex);
    event.target.blur();

  }

  updateBlockColor = (hex) => {

    this.chunkManager.blockColorHex = hex;
    this.setState({ blockColor: hex });

  }

  /**
   * ブロック追加
   */
  handleBlockRemoveClick = (event) => {

    event.button = 0;
    this.onDown(event);

  }

  /**
   * ブロックから色を選択する
   */
  handleColorPick = (event) => {

    event.button = 1;
    this.onDown(event);

  }

  /**
   * ブロック削除
   */
  handleBlockAddClick = (event) => {

    event.button = 2;
    this.onDown(event);

  }

  /**
   * ブロック編集モードの更新
   */
  updateBlockEditMode = (mode) => {

    this.setState({ blockEditMode: mode });

  }

  /**
   * 飛行モードのオン・オフ
   */
  handleFlyingModeClick = (event) => {

    const isFlying = !this.controls.isFlying;
    this.controls.isFlying = isFlying;
    this.setState({ isFlying });

  }

  /**
   * チャンクの読み込み状況
   */
  handleChunkLoad = (n) => {
    this.setState((state, props) => ({
      loadingCount: state.loadingCount + 1,
      loadingProgress: Math.floor(((state.loadingCount + 1) / n) * 100)
    }));
  }

  /**
   * 編集したボクセル数のカウント
   */
  handleVoxelsCountUpdate = () => {

    this.setState({
      addedVoxelsCount: this.addedVoxelsCount + this.chunkManager.addedVoxels.length,
      removedVoxelsCount: this.removedVoxelsCount + this.chunkManager.removedVoxels.length
    });

  }

  /**
   * ルーム情報更新
   */
  handleRoomUpdate = (data) => {

    this.setState((state, props) => ({
      numClients: data.numClients
    }));

  }

  /**
   * アバターの状態更新
   */
  handleAvatarUpdate = (data) => {

    this.avatars.update(data);

  }

  /**
   * ボクセル保存
   */
  handleSave = () => {

    this.chunkManager.saveChunks().then(result => {
      if (result.success) {
        console.log('Saved!');
        this.saveVoxelsCount();
      }
    });

  }

  /**
   * ワールドのスクリーンショット
   */
  handleScreenShot = () => {

    const img = this.renderer.domElement.toDataURL('image/png');
    const href = img.replace('image/png', 'image/octet-stream');
    this.downloadFile(href, 'pcedit.png');

  }

  /**
   * 選択した領域のボクセルをマイクラのファイル形式で書き出す
   */
  handleExportMinecraft = () => {

    const volume = this.chunkManager.copyArea();
    if (!volume) return;

    API.post('cloudvoxApi', '/exporter', {
      body: {
        fileType: 'minecraft',
        volume: volume
      }
    }).then(response => {
      this.downloadFile(response.url, `${this.worldName}.schematic`);
    });

  }

  /**
   * 選択した領域のボクセルをPLYで書き出す
   */
  handleExportPLY = () => {

    const volume = this.chunkManager.copyArea();
    if (!volume) return;

    API.post('cloudvoxApi', '/exporter', {
      body: {
        fileType: 'ply',
        volume: volume
      }
    }).then(response => {
      this.downloadFile(response.url, `${this.worldName}.ply`);
    });

  }

  /**
   * カメラ位置を初期位置に戻す
   */
  handleResetPosition = () => {

    this.camera.position.copy(this.defaultCameraPosition);

    this.camera.lookAt(
      this.defaultCameraTarget.x,
      this.defaultCameraTarget.y,
      this.defaultCameraTarget.z
    );

  }

  /**
   * 画面遷移前もしくは画面を閉じる前の処理
   */
  handleBeforeUnload = () => {

    const direction = new THREE.Vector3();
    this.camera.getWorldDirection(direction);

    const data = {
      camera: {
        position: {
          x: this.camera.position.x,
          y: this.camera.position.y,
          z: this.camera.position.z
        },
        target: {
          x: this.camera.position.x + direction.x,
          y: this.camera.position.y + direction.y,
          z: this.camera.position.z + direction.z
        }
      }
    };

    localStorage.setItem(this.worldName, JSON.stringify(data));

  }

  openColorPalette = () => {
    this.setState({ isColorPaletteVisible: true });
  }

  closeColorPalette = () => {
    this.setState({ isColorPaletteVisible: false });
  }

  /**
   * ファイルをダウンロード
   */
  downloadFile(href, fileName) {

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      document.body.appendChild(link);
      link.download = fileName;
      link.href = href;
      link.click();
      document.body.removeChild(link);
    }

  }

  /**
   * HTMLレンダリング
   */
  render() {

    return (
      <div>

        <Instructions
          title={this.state.title}
          credits={this.state.credits}
          show={!this.state.pointerLock}
          renderDistance={this.state.renderDistance}
          lastModified={this.state.lastModified}
          loadingProgress={this.state.loadingProgress}
          numClients={this.state.numClients}
          historyDate={this.state.historyDate}
          difference={this.state.difference}
          addedVoxelsCount={this.state.addedVoxelsCount}
          removedVoxelsCount={this.state.removedVoxelsCount}
          handlePointerLock={this.handlePointerLock}
          handleRenderDistanceChange={this.handleRenderDistanceChange}
          handleDifferenceChange={this.handleDifferenceChange}
          handleHistoryDateChange={this.handleHistoryDateChange}
        />

        <MousePointer show={this.state.pointerLock} />

        <MobileView>

          <div id="joystick-rotate"></div>
          <div id="joystick-move" className={this.state.pointerLock ? '' : 'hide'}></div>

          <BlockEditButtons
            show={this.state.pointerLock}
            blockColor={this.state.blockColor}
            blockEditMode={this.state.blockEditMode}
            openColorPalette={this.openColorPalette}
            handleColorPick={this.handleColorPick}
            handleBlockAddClick={this.handleBlockAddClick}
            handleBlockRemoveClick={this.handleBlockRemoveClick}
            handleBlockColorChange={this.handleBlockColorChange}
          />

          <BlockEditModeButtons
            show={this.state.pointerLock}
            blockEditMode={this.state.blockEditMode}
            updateBlockEditMode={this.updateBlockEditMode}
          />

          <ColorPalette
            show={this.state.isColorPaletteVisible}
            updateBlockColor={this.updateBlockColor}
            closeColorPalette={this.closeColorPalette}
          />

          <ModeButtons
            show={this.state.pointerLock}
            controls={this.controls}
            isFlying={this.state.isFlying}
            handleFlyingModeClick={this.handleFlyingModeClick}
          />

        </MobileView>

        <canvas ref={ref => (this.canvas = ref)} />

      </div>
    );

  }

}

export default Scene;
