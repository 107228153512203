import React from 'react';
import Scene from './components/scene';

import './App.css';

import Amplify from 'aws-amplify';
import awsConfig from './awsConfig';

Amplify.configure(awsConfig);


class App extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-body">
          <Scene />
        </div>
      </div>
    );
  }
}

export default App;
