import {
  randomInt,
} from './utils';


class WorkerPool {
  constructor(scriptUrl, handleMessage, numWorkers) {
    this.numWorkers = numWorkers;
    this.workers = [];

    for (let i = 0; i < numWorkers; i++) {
      let worker = new Worker(scriptUrl);
      worker.addEventListener('message', handleMessage);
      this.workers.push(worker);
    }
  }

  getRandom() {
    return this.workers[randomInt(this.numWorkers)];
  }
}

export default WorkerPool;
