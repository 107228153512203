import React, { useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";

import Editor from './editor';

import logo from '../assets/logo-yellow-whitebase.svg';
import howto from '../assets/how-to.svg';
import welcome1 from '../assets/welcome1.svg';
import welcome2 from '../assets/welcome2.svg';
import rotateDeviceImg from '../assets/rotate-device.svg';

/**
 * ホーム画面
 */
function Home(props) {

  const [isMobileVertical, setIsMobileVertical] = useState(false);

  useEffect(() => {
    const onResize = () => {
        setIsMobileVertical(window.innerWidth < window.innerHeight);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  let content = (
    <div className="content">
      <div className={isMobile ? 'logo mobile' : 'logo'}>
        <img src={logo} alt="logo" />
      </div>
      {props.loadingProgress < 100 &&
        <p>Loading {props.loadingProgress}%</p>
      }
      <div className="btn-container">
        <button
          className="btn green animate"
          onClick={props.handleStartClick}
          disabled={props.loadingProgress < 100}>
          START
        </button>
      </div>
      <button className="btn2 set animate" onClick={props.handleButtonClick} data-index="2">SETTING</button>
      <button className="btn2 set animate" onClick={props.handleButtonClick} data-index="1">LOGIN</button>

      <p>
        Last modified: {props.lastModified}
      </p>

      {props.children}
    </div>
  );

  if (isMobile && isMobileVertical) {
    content = (
      <div className="content">
        <img
          src={rotateDeviceImg}
          alt="rotate-device"
          style={{
            position: 'absolute',
            width: '70%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto'
          }}
        />
      </div>
    );
  }

  return (
    <div className="container">
      {content}
    </div>
  );

}

/**
 * ようこそ画面
 */
function Welcome(props) {

  return (
    <div className="container">
      <div className="content">
        <div className="welcome-message">
          <img src={welcome1} alt="welcome1" />
          <img src={welcome2} alt="welcome2" style={{ marginTop: '60px', marginBottom: '-70px' }} />
        </div>
        <div className="btn-container">
          <button
            className="btn green animate"
            onClick={props.handlePlayClick}>
            PLAY
          </button>
        </div>
      </div>
    </div>
  );

}

/**
 * ログイン画面
 */
function Login(props) {

  return (
    <div className="container">
      <div className="content">
        <Editor
          addedVoxelsCount={props.addedVoxelsCount}
          removedVoxelsCount={props.removedVoxelsCount}
        />
        <button
          className="btn2 set animate"
          onClick={props.handleButtonClick}
          data-index="0">
          Back
        </button>
      </div>
    </div>
  );

}

/**
 * 設定画面
 */
function Setting(props) {

  return (
    <div className="container">
      <div className="content">
        <div className="howto">
          <img src={howto} alt="howto" />
        </div>
        <p>
          History (UTC):<br />
          <input
            type="date"
            value={props.historyDate}
            onChange={props.handleHistoryDateChange}
          /><br />
          <input
            type="checkbox"
            id="difference"
            className="cbx"
            value="1"
            checked={props.difference}
            onChange={props.handleDifferenceChange}
          />
          <label htmlFor="difference">Difference</label>
        </p>
        <p>
          Render distance:<br />
          <input
            type="number"
            value={props.renderDistance}
            onChange={props.handleRenderDistanceChange}
          />
        </p>
        <button
          className="btn2 set animate"
          onClick={props.handleButtonClick}
          data-index="0">
          Back
        </button>
      </div>
    </div>
  );

}

/**
 * メニュー画面
 */
class Instructions extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      component: 0
    };

  }

  handleButtonClick = (event) => {

    const index = parseInt(event.currentTarget.getAttribute('data-index'));
    this.setState({ component: index });

  }

  handleStartClick = (event) => {

    // const isVisited = localStorage.getItem('machicad.isVisited');

    if (false) {
      this.props.handlePointerLock();
    } else {
      // ようこそ画面に切り替える
      this.setState({ component: 3 });
      localStorage.setItem('machicad.isVisited', 'yes');
    }

  }

  handlePlayClick = (event) => {

    this.props.handlePointerLock();
    this.setState({ component: 0 });

  }

  handleRenderDistanceChange = (event) => {
    this.props.handleRenderDistanceChange(event.target.value);
  }

  handleHistoryDateChange = (event) => {
    this.props.handleHistoryDateChange(event.target.value);
  }

  render() {

    if (!this.props.show) return null;

    const common = (<p>Participants: {this.props.numClients}</p>);

    return (
      <div id="instructions" className={isMobile ? 'mobile' : ''}>
        {this.state.component === 0 &&
          <Home
            title={this.props.title}
            credits={this.props.credits}
            lastModified={this.props.lastModified}
            loadingProgress={this.props.loadingProgress}
            handleStartClick={this.handleStartClick}
            handleButtonClick={this.handleButtonClick}>
            {common}
          </Home>
        }
        {this.state.component === 1 &&
          <Login
            addedVoxelsCount={this.props.addedVoxelsCount}
            removedVoxelsCount={this.props.removedVoxelsCount}
            title={this.props.title}
            credits={this.props.credits}
            renderDistance={this.props.renderDistance}
            lastModified={this.props.lastModified}
            loadingProgress={this.props.loadingProgress}
            historyDate={this.props.historyDate}
            difference={this.props.difference}
            handleButtonClick={this.handleButtonClick}
          />
        }
        {this.state.component === 2 &&
          <Setting
            addedVoxelsCount={this.props.addedVoxelsCount}
            removedVoxelsCount={this.props.removedVoxelsCount}
            title={this.props.title}
            credits={this.props.credits}
            renderDistance={this.props.renderDistance}
            lastModified={this.props.lastModified}
            loadingProgress={this.props.loadingProgress}
            historyDate={this.props.historyDate}
            difference={this.props.difference}
            handleButtonClick={this.handleButtonClick}
            handleRenderDistanceChange={this.handleRenderDistanceChange}
            handleDifferenceChange={this.props.handleDifferenceChange}
            handleHistoryDateChange={this.handleHistoryDateChange}
          />
        }
        {this.state.component === 3 &&
          <Welcome
            handlePlayClick={this.handlePlayClick}
          />
        }
      </div>
    );

  }
}

export default Instructions;
