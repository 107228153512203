import React from 'react';


class MousePointer extends React.Component {
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div id="mousePointer">+</div>
    );
  }
}

export default MousePointer;
