import React from 'react';

import BlockEditModes from '../assets/js/blockEditModes';

import arrowUpIcon from '../assets/arrow-up.svg';
import arrowDownIcon from '../assets/arrow-down.svg';
import blockAddIcon from '../assets/block-add.svg';
import blockRemoveIcon from '../assets/block-remove.svg';
import blockAddOnIcon from '../assets/block-add-on.svg';
import blockRemoveOnIcon from '../assets/block-remove-on.svg';
import flyingOnIcon from '../assets/flying-on.svg';
import flyingOffIcon from '../assets/flying-off.svg';
import spoitIcon from '../assets/spoit.svg';


class BlockEditButtons extends React.Component {

  componentDidMount() {

    const elSpoitBtn = document.getElementById('spoit-btn');

    elSpoitBtn.addEventListener('touchstart', this.handleButtonPress, { passive: false });
    elSpoitBtn.addEventListener('touchend', this.handleButtonRelease, { passive: false });

  }

  componentWillUnmount() {

    const elSpoitBtn = document.getElementById('spoit-btn');

    elSpoitBtn.removeEventListener('touchstart', this.handleButtonPress);
    elSpoitBtn.removeEventListener('touchend', this.handleButtonRelease);

  }

  /**
   * ボタンタッチ開始時の処理
   */
  handleButtonPress = (event) => {

    event.preventDefault();
    this.isButtonTimerEnd = false;

    this.buttonTimer = setTimeout(() => {
      this.props.openColorPalette();
      this.isButtonTimerEnd = true;
    }, 1000);

  }

  /**
   * ボタンタッチ終了時の処理
   */
  handleButtonRelease = (event) => {

    event.preventDefault();
    clearTimeout(this.buttonTimer);

    if (!this.isButtonTimerEnd) {
      this.props.handleColorPick(event);
    }

  }

  getAddButton() {

    return (
      <button
        className="block-btn"
        onClick={this.props.handleBlockAddClick}>
        <img
          src={blockAddIcon}
          alt="block-add"
        />
        <div
          className="block-color"
          style={{ background: this.props.blockColor }}
        ></div>
      </button>
    );

  }

  getRemoveButton() {

    return (
      <button
        className="block-btn"
        onClick={this.props.handleBlockRemoveClick}>
        <img
          src={blockRemoveIcon}
          alt="block-remove"
        />
      </button>
    );

  }

  getButton() {

    switch (this.props.blockEditMode) {

      case BlockEditModes.ADD:
        return this.getAddButton();

      case BlockEditModes.REMOVE:
        return this.getRemoveButton();

      default:
    }

  }

  render() {

    return (
      <div id="buttons-blockedit" className={this.props.show ? '' : 'hide'}>

        <input
          type="color"
          className="colorpicker"
          value={this.props.blockColor}
          onChange={this.props.handleBlockColorChange}
        />

        <button
          id="spoit-btn"
          className="icon-btn mr-2">
          <img
            src={spoitIcon}
            alt="spoit"
          />
        </button>

        {this.getButton()}
      </div>
    );

  }

}


class BlockEditModeButtons extends React.Component {

  handleClick = (event) => {

    let mode;

    if (this.props.blockEditMode === BlockEditModes.ADD) {
      mode = BlockEditModes.REMOVE;
    } else {
      mode = BlockEditModes.ADD;
    }

    this.props.updateBlockEditMode(mode);

  }

  getIcon() {

    switch (this.props.blockEditMode) {

      case BlockEditModes.ADD:
        return blockAddOnIcon;

      case BlockEditModes.REMOVE:
        return blockRemoveOnIcon;

      default:
    }

  }

  render() {

    return (
      <div id="buttons-blockedit-mode" className={this.props.show ? '' : 'hide'}>
        <button
          className="blockedit-mode-btn"
          onClick={this.handleClick}>
          <img src={this.getIcon()} alt="block-add-on" />
        </button>
      </div>
    );

  }

}


class MoveButtons extends React.Component {

  componentDidMount() {

    const elMoveUpBtn = document.getElementById('move-up-btn');
    const elMoveDownBtn = document.getElementById('move-down-btn');

    elMoveUpBtn.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    elMoveUpBtn.addEventListener('touchend', this.handleTouchEnd, { passive: false });

    elMoveDownBtn.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    elMoveDownBtn.addEventListener('touchend', this.handleTouchEnd, { passive: false });

  }

  componentWillUnmount() {

    const elMoveUpBtn = document.getElementById('move-up-btn');
    const elMoveDownBtn = document.getElementById('move-down-btn');

    elMoveUpBtn.removeEventListener('touchstart', this.handleTouchStart);
    elMoveUpBtn.removeEventListener('touchend', this.handleTouchEnd);

    elMoveDownBtn.removeEventListener('touchstart', this.handleTouchStart);
    elMoveDownBtn.removeEventListener('touchend', this.handleTouchEnd);

  }

  /**
   * スクリーンタッチ開始時の処理
   */
  handleTouchStart = (event) => {

    event.preventDefault();

    const type = event.target.dataset.name;
    this.props.controls.verticalMove(type);

  }

  /**
   * スクリーンタッチ終了時の処理
   */
  handleTouchEnd = (event) => {

    event.preventDefault();

    const type = event.target.dataset.name;
    this.props.controls.verticalMoveStop(type);

  }

  render() {
    return (
      <div id="buttons-move" className={this.props.show ? '' : 'hide'}>

        <div className={this.props.isFlying ? 'move-btn' : 'hide'}>
          <img
           id="move-up-btn"
           data-name="moveUp"
           src={arrowUpIcon}
           alt="move-up"
          />
        </div>

        <div className={this.props.isFlying ? 'move-btn' : 'hide'}>
          <img
           id="move-down-btn"
           data-name="moveDown"
           src={arrowDownIcon}
           alt="move-down"
          />
        </div>

      </div>
    );
  }

}


class ModeButtons extends React.Component {

  render() {

    return (
      <div id="buttons-mode" className={this.props.show ? '' : 'hide'}>

        <button
          className="icon-btn"
          onClick={this.props.handleFlyingModeClick}>
          <img
            src={this.props.isFlying ? flyingOnIcon : flyingOffIcon}
            alt="flying mode"
          />
        </button>

        <MoveButtons
          show={this.props.show}
          controls={this.props.controls}
          isFlying={this.props.isFlying}
        />

      </div>
    );

  }

}


export { BlockEditButtons, BlockEditModeButtons, MoveButtons, ModeButtons }
