import * as THREE from 'three';
import ObjectTypes from './objectTypes';
import {
  hexToRgb,
  rgbToHex,
  voxelIndex,
} from './utils';


class Chunk {
  constructor(x, y, z, dim, objects, helpers) {
    this.position = new THREE.Vector3(x, y, z);
    this.dims = [dim, dim, dim];
    this.objects = objects;
    this.helpers = helpers;
    this.mesh = null;
    this.diff = null;
    this.voxels = null;
    this.isActive = true;
  }

  createMesh(geometry, material) {
    this.removeMesh();

    let mesh = new THREE.Mesh(geometry, material);
    mesh.userData.type = ObjectTypes.CHUNK;
    mesh.position.set(
      this.position.x * this.dims[0],
      this.position.y * this.dims[1],
      this.position.z * this.dims[2]
    );
    this.mesh = mesh;

    if (this.isActive) this.objects.add(this.mesh);
  }

  createDiff(geometry, material) {
    this.removeDiff();

    let diff = new THREE.Mesh(geometry, material);
    diff.userData.type = ObjectTypes.DIFF;
    diff.position.set(
      this.position.x * this.dims[0],
      this.position.y * this.dims[1],
      this.position.z * this.dims[2]
    );
    this.diff = diff;

    if (this.isActive) this.helpers.add(this.diff);
  }

  removeMesh() {
    if (this.mesh) {
      this.objects.remove(this.mesh);
      this.mesh.geometry.dispose();
      this.mesh = null;
    }
  }

  removeDiff() {
    if (this.diff) {
      this.helpers.remove(this.diff);
      this.diff.geometry.dispose();
      this.diff = null;
    }
  }

  getVoxel(position) {
    let dims = this.dims;
    let index = voxelIndex(
      position.x, position.y, position.z,
      dims[0], dims[1], 3
    );
    let r = this.voxels[index];
    let g = this.voxels[index + 1];
    let b = this.voxels[index + 2];
    return rgbToHex(r, g, b);
  }

  setVoxel(position, value) {
    let dims = this.dims;
    let index = voxelIndex(
      position.x, position.y, position.z,
      dims[0], dims[1], 3
    );
    let rgb = hexToRgb(value);
    this.voxels[index] = rgb[0];
    this.voxels[index + 1] = rgb[1];
    this.voxels[index + 2] = rgb[2];
    return this.voxels;
  }

  attach() {
    this.isActive = true;
    if (this.mesh) this.objects.add(this.mesh);
    if (this.diff) this.helpers.add(this.diff);
  }

  detach() {
    this.isActive = false;
    if (this.mesh) this.objects.remove(this.mesh);
    if (this.diff) this.helpers.remove(this.diff);
  }
}

export default Chunk;
