import React from 'react';
import {
  SignIn,
  RequireNewPassword,
  ForgotPassword,
  VerifyContact,
  Greetings,
  withAuthenticator
} from 'aws-amplify-react';


class Editor extends React.Component {

  render() {
    return (
      <div>
        <p>Logged In</p>
        <p>Added voxels: {this.props.addedVoxelsCount}</p>
        <p>Removed voxels: {this.props.removedVoxelsCount}</p>
      </div>
    );
  }

}

export default withAuthenticator(Editor, true, [
  <SignIn />,
  <RequireNewPassword />,
  <ForgotPassword />,
  <VerifyContact />,
  <Greetings />
]);
