import * as THREE from 'three';
import {PointerLockControls} from 'three/examples/jsm/controls/PointerLockControls';
import ChunkEditModes from './chunkEditModes';


class GameControls extends PointerLockControls {

  constructor(camera, domElement, chunkManager) {

    super(camera, domElement);

    this.chunkManager = chunkManager;

    this.isEntered = false;
    this.isFlying = false;
    this.isRunning = false;

    this.moveF = false; // forward
    this.moveB = false; // backward
    this.moveL = false; // left
    this.moveR = false; // right
    this.moveU = false; // up
    this.moveD = false; // down

    this.prevTime = performance.now();
    this.velocity = new THREE.Vector3();
    this.direction = new THREE.Vector3();
    this.targetVec = new THREE.Vector3();

    this.blockBottom = null;

    this.saveEvent = {type: 'save'};
    this.screenShotEvent = {type: 'screenshot'};
    this.exportMinecraftEvent = {type: 'exportMinecraft'};
    this.exportPLYEvent = {type: 'exportPLY'};
    this.resetPosition = {type: 'resetPosition'};

  }

  addEventListeners() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  removeEventListeners() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  lockedKeyDown(event) {

    switch (event.keyCode) {

      case 38: // up
      case 87: // w
        this.moveF = true;
        break;

      case 37: // left
      case 65: // a
        this.moveL = true;
        break;

      case 40: // down
      case 83: // s
        this.moveB = true;
        break;

      case 39: // right
      case 68: // d
        this.moveR = true;
        break;

      case 32: // space
        this.verticalMove('moveUp');
        break;

      case 16: // shift
        this.moveD = true;
        break;

      case 66: // b
        if (event.ctrlKey) {
          this.chunkManager.setArea(this.chunkManager.blockColor);
        }
        break;

      case 67: // c
        if (event.ctrlKey) {
          this.chunkManager.copyArea();
        } else {
          console.log(this.getObject());
        }
        break;

      case 70: // f
        this.isFlying = !this.isFlying;
        break;

      case 80: // p
        console.log(this.getCameraInfo());
        break;

      case 86: // v
        break;

      case 88: // x
        if (event.ctrlKey) {
          this.chunkManager.copyArea();
          this.chunkManager.setArea(0x000000);
        }
        break;

      case 90: // z
        break;

      case 49: // 1
        this.chunkManager.setMode(ChunkEditModes.POINT);
        break;

      case 50: // 2
        this.chunkManager.setMode(ChunkEditModes.DRAW);
        break;

      case 112: // F1
        this.dispatchEvent(this.saveEvent);
        break;

      case 113: // F2
        this.dispatchEvent(this.screenShotEvent);
        break;

      case 114: // F3
        const point = this.getObject().position;
        this.chunkManager.removeFloatingVoxels(point);
        break;

      case 115: // F4
        this.dispatchEvent(this.exportMinecraftEvent);
        break;

      case 116: // F5
        this.dispatchEvent(this.exportPLYEvent);
        break;

      default:
    }

  }

  lockedKeyUp(event) {
    switch (event.keyCode) {
      case 38: // up
      case 87: // w
        this.moveF = false;
        break;

      case 37: // left
      case 65: // a
        this.moveL = false;
        break;

      case 40: // down
      case 83: // s
        this.moveB = false;
        break;

      case 39: // right
      case 68: // d
        this.moveR = false;
        break;

      case 32: // space
        this.moveU = false;
        break;

      case 16: // shift
        this.moveD = false;
        break;

      default:
    }
  }

  getCameraInfo() {

    const camera = this.getObject();
    const direction = new THREE.Vector3();

    camera.getWorldDirection(direction);

    const data = {
      camera: {
        position: {
          x: camera.position.x,
          y: camera.position.y,
          z: camera.position.z
        },
        target: {
          x: camera.position.x + direction.x,
          y: camera.position.y + direction.y,
          z: camera.position.z + direction.z
        }
      }
    };

    return data;

  }

  handleKeyDown = (event) => {
    if (this.isLocked) {
      this.lockedKeyDown(event);
    }
  }

  handleKeyUp = (event) => {
    if (this.isLocked) {
      this.lockedKeyUp(event);
    }
  }

  moveUp = (distance) => {
    this.getObject().position.y += distance
  }

  verticalMove = (type) => {

    switch (type) {

      case 'moveUp':
        if (this.isFlying) {
          this.moveU = true;
        } else {
          this.jump();
        }
        break;

      case 'moveDown':
        this.moveD = true;
        break;

      default:
    }

  }

  verticalMoveStop = (type) => {

    switch (type) {

      case 'moveUp':
        this.moveU = false;
        break;

      case 'moveDown':
        this.moveD = false;
        break;

      default:
    }

  }

  jump() {
    // 地面についていたらジャンプ
    if (this.blockBottom) {
      this.velocity.y = -12;
    }
  }

  reset() {
    this.velocity.set(0, 0, 0);
  }

  enterWorld() {
    this.isEntered = true;
  }

  exitWorld() {
    this.isEntered = false;
    this.reset();
  }

  collision() {
    if (this.chunkManager.isReady()) {
      let point = this.getObject().position.clone();

      // bottom
      point.y -= 3;
      this.blockBottom = this.chunkManager.getBlock(point);
      if (this.blockBottom) {
        this.velocity.y = Math.min(0, this.velocity.y);
      }

      // front
      this.getDirection(this.targetVec);
      this.targetVec.y = 0;
      this.targetVec.normalize();

      point.y += 0.5;
      point.add(this.targetVec);

      const blockFront = this.chunkManager.getBlock(point);
      if (blockFront) {
        this.velocity.z = Math.max(0, this.velocity.z);
      }

      // back
      const b = this.targetVec.clone().negate();
      b.multiplyScalar(2);
      point.add(b);

      const blockBack = this.chunkManager.getBlock(point);
      if (blockBack) {
        this.velocity.z = Math.min(0, this.velocity.z);
      }

      // 前に進んでいるとき、段差にぶつかったら自動的にジャンプ
      if (!this.isFlying && this.blockBottom && blockFront && this.moveF) {
        this.velocity.y = -7;
      }
    }
  }

  update() {
    const time = performance.now();

    if (this.isLocked || this.isEntered) {
      const delta = (time - this.prevTime) / 1000;
      const speed = this.isRunning ? 5.0 : 10.0;

      // 移動速度
      this.velocity.x -= this.velocity.x * speed * delta;
      this.velocity.z -= this.velocity.z * speed * delta;

      if (this.isFlying) {
        this.velocity.y -= this.velocity.y * speed * delta;
      } else {
        this.velocity.y += 9.8 * 3 * delta; // 重力
      }

      // 進行方向
      this.direction.z = Number(this.moveF) - Number(this.moveB);
      this.direction.x = Number(this.moveR) - Number(this.moveL);
      this.direction.y = Number(this.moveU) - Number(this.moveD);
      this.direction.normalize();

      if (this.moveF || this.moveB) {
        this.velocity.z -= this.direction.z * 150.0 * delta;
      }
      if (this.moveR || this.moveL) {
        this.velocity.x -= this.direction.x * 150.0 * delta;
      }
      if (this.moveU || this.moveD) {
        this.velocity.y -= this.direction.y * 150.0 * delta;
      }

      this.collision();

      this.moveRight(-this.velocity.x * delta);
      this.moveForward(-this.velocity.z * delta);
      this.moveUp(-this.velocity.y * delta);

      if (this.getObject().position.y < -512) {
        this.dispatchEvent(this.resetPosition);
        this.reset();
      }
    }

    this.prevTime = time;
  }
}

export default GameControls;
