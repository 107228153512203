import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


class Avatar {

  constructor(name) {

    this.group = new THREE.Group();
    this.group.name = name;
    this.name = name;
    this.state = 'Idle';

    const loader = new GLTFLoader();
		loader.load('/models/RobotExpressive.glb', (gltf) => {
			const model = gltf.scene;
      model.position.y = -2;
			this.group.add(model);
      this.setup(model, gltf.animations);
		}, undefined, (e) => {
			console.error(e);
		});

  }

  setup(model, animations) {

    const states = ['Idle', 'Walking', 'Running', 'Dance', 'Death', 'Sitting', 'Standing'];
		const emotes = ['Jump', 'Yes', 'No', 'Wave', 'Punch', 'ThumbsUp'];

    const mixer = new THREE.AnimationMixer(model);
    const actions = {};

    for (let clip of animations) {
      const action = mixer.clipAction(clip);
      actions[clip.name] = action;

      if (emotes.indexOf(clip.name) >= 0 || states.indexOf(clip.name) >= 4) {
        action.clampWhenFinished = true;
        action.loop = THREE.LoopOnce;
      }
    }

    const activeAction = actions[this.state];
		activeAction.play();

    this.mixer = mixer;
    this.actions = actions;
    this.activeAction = activeAction;

  }

  update(delta) {

    if (this.mixer) this.mixer.update(delta);

  }

  takeAction(name) {

    this.fadeToAction(name, 0.2);
    this.mixer.addEventListener('finished', this.restoreState);

  }

  fadeToAction(name, duration) {

		const previousAction = this.activeAction;
		const activeAction = this.actions[name];

		if (previousAction !== activeAction) {
			previousAction.fadeOut(duration);
		}

		activeAction
			.reset()
			.setEffectiveTimeScale(1)
			.setEffectiveWeight(1)
			.fadeIn(duration)
			.play();

	}

  restoreState = () => {

    this.mixer.removeEventListener('finished', this.restoreState);
    this.fadeToAction(this.state, 0.2);

  }

}


export default class Avatars {

  constructor() {

    this.group = new THREE.Group();
    this.avatars = [];

  }

  add(data) {

    const avatar = new Avatar(data.uuid);
    avatar.group.position.set(
      data.position.x,
      data.position.y,
      data.position.z
    );
    this.group.add(avatar.group);
    this.avatars.push(avatar);

  }

  update(data) {

    let object = this.group.getObjectByName(data.uuid);

    if (object) {
      // 存在するアバターの更新
      if (data.remove) {
        this.group.remove(object);
      } else {

        // 位置の更新
        object.position.set(
          data.position.x,
          data.position.y,
          data.position.z
        );
        // 向きの更新
        object.lookAt(
          data.position.x + data.direction.x,
          data.position.y + data.direction.y,
          data.position.z + data.direction.z
        );

        if (data.action) {
          const avatar = this.getAvatarByName(data.uuid);
          if (avatar) avatar.takeAction(data.action);
        }
      }
    } else {
      // アバターの追加
      this.add(data);
    }

  }

  animate(delta) {

    this.avatars.forEach((avatar, i) => {
      avatar.update(delta);
    });

  }

  getAvatarByName(name) {

    for (let avatar of this.avatars) {
      if (avatar.name === name) {
        return avatar;
      }
    }

  }

}
