import * as THREE from 'three';
import { API } from 'aws-amplify';
import Chunk from './chunk';
import ChunkEditModes from './chunkEditModes';
import SwitchModes from './switchModes';
import MessageTypes from './messageTypes';
import WorkerPool from './workerPool';
import {
  generateCoordKey,
  decodeCoordKey,
  isPastDate,
  zeros3d,
} from './utils';


class AreaEdit {
  constructor() {
    this.box = new THREE.Box3().setFromArray([0, 0, 0, 1, 1, 1]);
    this.helper = new THREE.Box3Helper(this.box, 0xff0000);
    this.helper.visible = false;

    this.startPos = new THREE.Vector3();
    this.endPos = new THREE.Vector3();

    this.isStartSet = false;
    this.isEndSet = false;
  }

  getVolume() {
    return this.volume;
  }

  setVolume(volume) {
    this.volume = volume;
  }

  setPosition(v) {
    if (this.isSelected()) {
      this.reset();
    }

    if (!this.isStartSet) {
      this.setStartPos(v);
    }
    else if (!this.isEndSet) {
      this.setEndPos(v);
    }
  }

  setStartPos(v) {
    this.startPos.copy(v);
    this.isStartSet = true;
    this.updateBox();
  }

  setEndPos(v) {
    this.endPos.copy(v);
    this.isEndSet = true;
    this.updateBox();
  }

  updateBox() {
    this.box.setFromPoints([this.startPos, this.endPos]);
    this.box.min.floor();
    this.box.max.ceil();

    if (this.isStartSet && this.isEndSet) {
      this.helper.visible = true;
    } else {
      this.helper.visible = false;
    }
  }

  isSelected() {
    return this.isStartSet && this.isEndSet;
  }

  reset() {
    this.isStartSet = false;
    this.isEndSet = false;
    this.updateBox();
  }
}


class VoxelDraw {
  constructor() {
    this.voxels = [];
    this.helpers = new THREE.Group();
    this.boxGeometry = new THREE.BoxBufferGeometry(1, 1, 1);
  }

  addVoxel(point, color) {
    const key = generateCoordKey(point.x, point.y, point.z);
    if (this.voxels.includes(key)) return;

    const material = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0.4,
      polygonOffset: true,
      polygonOffsetFactor: -0.1
    });
    const mesh = new THREE.Mesh(this.boxGeometry, material);
    mesh.position.copy(point).addScalar(0.5);

    this.helpers.add(mesh);
    this.voxels.push(key);
  }

  removeVoxels() {
    for (let i = this.helpers.children.length - 1; i >= 0; i--) {
      this.helpers.remove(this.helpers.children[i]);
    }
    this.voxels = [];
  }
}


class ChunkManager {

  constructor(worldName, renderDistance, historyDate, sound, socket) {

    this.worldName = worldName;
    this.horizontalRenderDistance = renderDistance; // chunks
    this.verticalRenderDistance = 3;
    this.historyDate = historyDate;
    this.sound = sound;
    this.socket = socket;

    const hrd = this.horizontalRenderDistance;
    const vrd = this.verticalRenderDistance;
    this.firstNumChunks = Math.pow((2 * hrd - 1), 2) * (2 * vrd - 1);

    this.onChunkCreate = () => {};
    this.onChunkLoad = () => {};
    this.onVoxelsCountUpdate = () => {};

    this.addedVoxels = [];
    this.removedVoxels = [];

    this.objects = new THREE.Group();
    this.helpers = new THREE.Group();

    this.position = new THREE.Vector3();
    this.lastPos = new THREE.Vector3();
    this.chunkPos = new THREE.Vector3();
    this.localPos = new THREE.Vector3();

    this.mode = ChunkEditModes.POINT;
    this.difference = SwitchModes.OFF;
    this.chunkDim = 32;
    this.blockSize = 1;
    this._blockColor = 0xffffff;

    this.chunks = {};
    this.cache = {chunks: {}, unsaved: []};

    this.material = new THREE.MeshLambertMaterial({
      vertexColors: THREE.VertexColors
    });
    this.materialDiff = new THREE.MeshBasicMaterial({
      vertexColors: THREE.VertexColors,
      // transparent: true,
      // opacity: 0.4,
      polygonOffset: true,
      polygonOffsetFactor: -0.1,
      wireframe: true,
    });

    this.rebuildWorkers = new WorkerPool('workers/rebuild.js', this.handleRebuildMessage, 4);

    this.areaEdit = new AreaEdit();
    this.helpers.add(this.areaEdit.helper);

    this.voxelDraw = new VoxelDraw();
    this.helpers.add(this.voxelDraw.helpers);

  }

  get blockColor() {

    return this._blockColor;

  }

  get blockColorHex() {

    return `#${this._blockColor.toString(16)}`;

  }

  set blockColorHex(hexString) {

    let _hexString = hexString;
    if (_hexString.startsWith('#')) {
      _hexString = _hexString.substring(1);
    }
    this._blockColor = parseInt(_hexString, 16);

  }

  /**
   * ボクセル更新メッセージの処理
   */
  handleRebuildMessage = (message) => {

    let data = message.data;
    let geometry = new THREE.BufferGeometry();

    if (data.vertices) {
      geometry.setIndex(new THREE.BufferAttribute(data.faces, 1));
      geometry.setAttribute('position', new THREE.BufferAttribute(data.vertices, 3));
      geometry.setAttribute('normal', new THREE.BufferAttribute(data.normals, 3));
      geometry.setAttribute('color', new THREE.BufferAttribute(data.colors, 3));
    }

    let key = generateCoordKey(data.position);
    let chunk = this.chunks[key] || this.cache.chunks[key];

    if (chunk) {
      if (data.isDifference) {
        chunk.createDiff(geometry, this.materialDiff);
      } else {
        chunk.createMesh(geometry, this.material);
        chunk.voxels = data.voxels;
      }
    }

    if (!data.isDifference) {
      this.onChunkLoad(this.firstNumChunks);
    }

  }

  /**
   * ボクセルの同期
   */
  syncVoxels(data) {

    const keys = [];

    for (let i = 0; i < data.voxels.length; i++) {
      const voxel = data.voxels[i];
      const point = new THREE.Vector3(
        voxel.point[0],
        voxel.point[1],
        voxel.point[2]
      );

      const result = this.locatePosition(point);
      const chunk = this.chunks[result.key] || this.cache.chunks[result.key];

      if (chunk) {
        chunk.setVoxel(result.localPos, voxel.value);

        if (!keys.includes(result.key)) {
          keys.push(result.key);
        }
      }
    }

    this.rebuildMeshes(keys);

  }

  setDifference(difference) {
    this.difference = difference;
  }

  setMode(mode) {
    this.mode = mode;
  }

  setHistoryDate(historyDate) {
    this.historyDate = historyDate;

    if (isPastDate(historyDate)) {
      this.setMode(Math.abs(this.mode) * -1);
    } else {
      this.setMode(Math.abs(this.mode));
    }
  }

  getEditableObjects() {
    const x = this.position.x;
    const y = this.position.y;
    const z = this.position.z;
    let objects = [];

    // nearest 3 x 3 x 3 chunks
    for (let k = -1; k < 2; k++)
    for (let j = -1; j < 2; j++)
    for (let i = -1; i < 2; i++) {
      const key = generateCoordKey(x + i, y + j, z + k);
      const mesh = this.chunks[key].mesh;
      if (mesh) objects.push(mesh);
    }

    return objects;
  }

  mouseEdit(event, intersect) {
    if (this.mode !== ChunkEditModes.POINT) return;
    let point = intersect.point;
    let normal = intersect.face.normal;

    switch ([event.button, event.altKey, event.ctrlKey].toString()) {

      case [0, false, false].toString():
        // Remove voxel
        point.add(normal.multiplyScalar(-0.5));
        this.setBlock(point, 0x000000);
        this.sound.breakBlock();
        this.appendRemovedVoxels(point);
        this.onVoxelsCountUpdate();
        break;

      case [1, false, false].toString():
        // Pick color
        point.add(normal.multiplyScalar(-0.5));
        this._blockColor = this.getBlock(point);
        break;

      case [2, false, false].toString():
        // Add voxel
        point.add(normal.multiplyScalar(0.5));
        this.setBlock(point, this._blockColor);
        this.sound.createBlock();
        this.appendAddedVoxels(point);
        this.onVoxelsCountUpdate();
        break;

      case [0, true, false].toString():
        // Select position 1 and 2
        point.add(normal.multiplyScalar(-0.5));
        this.areaEdit.setPosition(point);
        break;

      case [2, true, true].toString():
        // paste area
        point.add(normal.multiplyScalar(-0.5));
        this.pasteArea(point);
        break;

      default:
    }
  }

  beginDraw(button, intersect) {
    if (this.mode !== ChunkEditModes.DRAW) return;
    let color = 0x000000;
    let offset = -0.5;

    if (button === 2) {
      color = this._blockColor;
      offset = 0.5;
    }

    const point = intersect.point;
    const normal = intersect.face.normal;
    point.add(normal.multiplyScalar(offset)).floor();

    this.voxelDraw.addVoxel(point, color);
  }

  endDraw(button) {
    let color = 0x000000;

    if (button === 2) color = this._blockColor;

    const voxels = this.voxelDraw.voxels;
    const point = new THREE.Vector3();
    const keys = [];
    const data = {
      type: MessageTypes.VOXEL,
      voxels: []
    };

    for (let i = 0; i < voxels.length; i++) {
      const voxel = voxels[i].split('|').map(x => parseInt(x));
      point.set(voxel[0], voxel[1], voxel[2]);

      const result = this.locatePosition(point);
      this.chunks[result.key].setVoxel(result.localPos, color);

      if (!keys.includes(result.key)) {
        keys.push(result.key);
      }

      data.voxels.push({
        point: [point.x, point.y, point.z],
        value: color
      });
    }

    this.rebuildMeshes(keys);
    this.voxelDraw.removeVoxels();
    this.socket.send(JSON.stringify(data));
  }

  appendAddedVoxels(point) {
    const voxel = point.clone().floor();
    const key = generateCoordKey(voxel.x, voxel.y, voxel.z);

    if (this.removedVoxels.includes(key)) {
      const index = this.removedVoxels.indexOf(key);
      this.removedVoxels.splice(index, 1);
    }
    else if (!this.addedVoxels.includes(key)) {
      this.addedVoxels.push(key);
    }
  }

  appendRemovedVoxels(point) {
    const voxel = point.clone().floor();
    const key = generateCoordKey(voxel.x, voxel.y, voxel.z);

    if (this.addedVoxels.includes(key)) {
      const index = this.addedVoxels.indexOf(key);
      this.addedVoxels.splice(index, 1);
    }
    else if (!this.removedVoxels.includes(key)) {
      this.removedVoxels.push(key);
    }
  }

  chunkPosFromPoint(point) {
    // chunk position
    let chunkPos = point.clone();
    chunkPos.divideScalar(this.chunkDim).floor();
    return chunkPos;
  }

  localPosFromPoint(point, chunkPos) {
    // local position in chunk
    let localPos = point.clone();
    localPos.floor().sub(chunkPos.clone().multiplyScalar(this.chunkDim));
    return localPos;
  }

  locatePosition(point) {
    const chunkPos = this.chunkPosFromPoint(point)
        , localPos = this.localPosFromPoint(point, chunkPos)
        , key = generateCoordKey(chunkPos.x, chunkPos.y, chunkPos.z);
    return {key: key, localPos: localPos};
  }

  getBlock(point) {
    const chunkPos = this.chunkPosFromPoint(point)
        , localPos = this.localPosFromPoint(point, chunkPos)
        , key = generateCoordKey(chunkPos.x, chunkPos.y, chunkPos.z);
    return this.chunks[key].getVoxel(localPos);
  }

  setBlock(point, value) {
    const result = this.locatePosition(point);
    const chunk = this.chunks[result.key];

    chunk.setVoxel(result.localPos, value);
    this.rebuildMesh(result.key);

    this.socket.send(JSON.stringify({
      type: MessageTypes.VOXEL,
      voxels: [
        {
          point: [point.x, point.y, point.z],
          value: value
        }
      ]
    }));
  }

  setArea(value) {
    if (!this.areaEdit.isSelected()) return;

    const minX = this.areaEdit.box.min.x;
    const minY = this.areaEdit.box.min.y;
    const minZ = this.areaEdit.box.min.z;

    const maxX = this.areaEdit.box.max.x;
    const maxY = this.areaEdit.box.max.y;
    const maxZ = this.areaEdit.box.max.z;

    const point = new THREE.Vector3();
    const keys = [];
    const data = {
      type: MessageTypes.VOXEL,
      voxels: []
    };

    // set voxels in selected area
    for (let z = minZ; z < maxZ; z++)
    for (let y = minY; y < maxY; y++)
    for (let x = minX; x < maxX; x++) {
      point.set(x, y, z);

      const result = this.locatePosition(point);
      this.chunks[result.key].setVoxel(result.localPos, value);

      if (keys.indexOf(result.key) < 0) {
        keys.push(result.key);
      }

      data.voxels.push({
        point: [point.x, point.y, point.z],
        value: value
      });
    }

    this.rebuildMeshes(keys);
    this.socket.send(JSON.stringify(data));
  }

  copyArea() {
    if (!this.areaEdit.isSelected()) return;

    const minX = this.areaEdit.box.min.x;
    const minY = this.areaEdit.box.min.y;
    const minZ = this.areaEdit.box.min.z;

    const maxX = this.areaEdit.box.max.x;
    const maxY = this.areaEdit.box.max.y;
    const maxZ = this.areaEdit.box.max.z;

    const sizeX = maxX - minX;
    const sizeY = maxY - minY;
    const sizeZ = maxZ - minZ;
    const volume = zeros3d(sizeX, sizeY, sizeZ);

    const point = new THREE.Vector3();

    // set voxels in selected area
    for (let z = minZ; z < maxZ; z++)
    for (let y = minY; y < maxY; y++)
    for (let x = minX; x < maxX; x++) {
      point.set(x, y, z);

      const result = this.locatePosition(point);
      const voxel = this.chunks[result.key].getVoxel(result.localPos);

      volume[z-minZ][y-minY][x-minX] = voxel;
    }

    this.areaEdit.setVolume(volume);
    console.log('copy area');
    return volume;
  }

  pasteArea(refPoint) {
    if (!this.areaEdit.getVolume()) return;

    const volume = this.areaEdit.getVolume();
    const keys = [];
    const data = {
      type: MessageTypes.VOXEL,
      voxels: []
    };

    for (let z = 0; z < volume.length; z++)
    for (let y = 0; y < volume[z].length; y++)
    for (let x = 0; x < volume[z][y].length; x++) {
      const point = refPoint.clone().add(new THREE.Vector3(x, y, z));
      const value = volume[z][y][x];

      const result = this.locatePosition(point);
      this.chunks[result.key].setVoxel(result.localPos, value);

      if (keys.indexOf(result.key) < 0) {
        keys.push(result.key);
      }

      data.voxels.push({
        point: [point.x, point.y, point.z],
        value: value
      });
    }

    this.rebuildMeshes(keys);
    this.socket.send(JSON.stringify(data));
    console.log('paste area');
  }

  removeFloatingVoxels(point) {
    const globalPosition = point.clone().floor();
    const h = this.chunkDim * 0.5;

    let p = new THREE.Vector3();
    let q = new THREE.Vector3();
    let keys = [];
    let syncData = {
      type: MessageTypes.VOXEL,
      voxels: []
    };

    for (let k = -h; k < h; k++)
    for (let j = -h; j < h; j++)
    for (let i = -h; i < h; i++) {
      p.x = globalPosition.x + i;
      p.y = globalPosition.y + j;
      p.z = globalPosition.z + k;

      const result = this.locatePosition(p);
      const voxel = this.chunks[result.key].getVoxel(result.localPos);

      if (voxel > 0) {
        let sumVoxels = 0;

        for (let l = -1; l < 2; l++)
        for (let n = -1; n < 2; n++)
        for (let m = -1; m < 2; m++) {
          if (m === 0 && n === 0 && l === 0) continue;

          q.x = p.x + m;
          q.y = p.y + n;
          q.z = p.z + l;

          const result = this.locatePosition(q);
          sumVoxels += this.chunks[result.key].getVoxel(result.localPos);
        }

        if (sumVoxels === 0) {
          this.chunks[result.key].setVoxel(result.localPos, 0);

          if (!keys.includes(result.key)) {
            keys.push(result.key);
          }

          syncData.voxels.push({
            point: [p.x, p.y, p.z],
            value: 0
          });
        }
      }
    }

    this.rebuildMeshes(keys);
    this.socket.send(JSON.stringify(syncData));
  }

  createMesh(x, y, z) {
    const name = this.worldName;
    const date = this.historyDate.replace(/-/g, '');
    const diff = this.difference;

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/world`
                   + `?name=${name}&date=${date}&diff=${diff}`
                   + `&x=${x}&y=${y}&z=${z}`;

    // Send data to worker
    this.rebuildWorkers.getRandom().postMessage({
      apiUrl: apiUrl,
      position: [x, y, z],
      dim: this.chunkDim
    });
  }

  rebuildMesh(key) {
    const chunk = this.chunks[key];

    // Send data to worker
    // TODO: fix failed to execute 'postMessage' on 'Worker': ArrayBuffer at index 0 is
    // already detached.
    this.rebuildWorkers.getRandom().postMessage({
      position: [chunk.position.x, chunk.position.y, chunk.position.z],
      dim: this.chunkDim,
      voxels: chunk.voxels
    }, [chunk.voxels.buffer]);

    // Record as unsaved chunk
    if (this.cache.unsaved.indexOf(key) < 0) {
      this.cache.unsaved.push(key);
    }
  }

  rebuildMeshes(keys) {
    for (let i = 0; i < keys.length; i++) {
      this.rebuildMesh(keys[i]);
    }
  }

  unsavedChunksExist() {
    return this.cache.unsaved.length > 0;
  }

  async saveChunks() {
    let unsaved = this.cache.unsaved.splice(0, this.cache.unsaved.length);
    let errors = [];

    for (let i = 0; i < unsaved.length; i++) {
      let key = unsaved[i];
      let chunk = this.chunks[key] || this.cache.chunks[key];
      if (chunk) {
        // Encode byte array to string
        let strData = "";
        for (let v = 0; v < chunk.voxels.length; v++) {
          strData += String.fromCharCode(chunk.voxels[v]);
        }
        // Encode string to base64
        let b64Data = btoa(strData);

        // Send data to server
        await API.post('cloudvoxApi', '/world', {
          body: {
            name: this.worldName,
            x: chunk.position.x,
            y: chunk.position.y,
            z: chunk.position.z,
            data: b64Data
          }
        }).catch(error => {
          console.log(error);
          errors.push(key);
        });
      }
    }

    // Failed to save
    if (errors.length > 0) return {success: false};

    // Successfully saved
    return {success: true};
  }

  isReady() {
    const x = this.position.x
        , y = this.position.y
        , z = this.position.z
        , key = generateCoordKey(x, y, z);
    return key in this.chunks && this.chunks[key].voxels;
  }

  createChunk(key, x, y, z) {
    this.chunks[key] = new Chunk(x, y, z, this.chunkDim, this.objects, this.helpers);
    this.createMesh(x, y, z);
    // this.onChunkCreate();
  }

  refresh() {
    const chunkKeys = Object.keys(this.chunks);

    for (let i = 0; i < chunkKeys.length; i++) {
      const key = chunkKeys[i];
      const coord = decodeCoordKey(key);
      this.chunks[key].removeDiff();
      this.createMesh(coord[0], coord[1], coord[2]);
      // this.onChunkCreate();
    }

    // TODO: does this affect other parts?
    this.cache.chunks = {};
  }

  update(camera) {
    // Current chunk position
    this.position.copy(camera.position).divideScalar(this.chunkDim).floor();

    if (this.lastPos.equals(this.position)) return;

    let x = this.position.x
      , y = this.position.y
      , z = this.position.z
      , hrd = this.horizontalRenderDistance
      , vrd = this.verticalRenderDistance
      , chunkKeys = Object.keys(this.chunks);

    // Add near chunks
    for (let k = - hrd + 1; k < hrd; k++)
    for (let j = - vrd + 1; j < vrd; j++)
    for (let i = - hrd + 1; i < hrd; i++) {
      let cx = x + i
        , cy = y + j
        , cz = z + k
        , key = generateCoordKey(cx, cy, cz);

      // Check if a chunk exists
      if (!(key in this.chunks)) {
        // Check if the chunk is cached
        if (!(key in this.cache.chunks)) {
          this.createChunk(key, cx, cy, cz);
        } else {
          // Restore the chunk from cache
          let chunk = this.cache.chunks[key];
          chunk.attach();
          this.chunks[key] = chunk;
        }
      } else {
        chunkKeys.splice(chunkKeys.indexOf(key), 1);
      }
    }

    // Remove far chunks and cache them
    for (let i = 0; i < chunkKeys.length; i++) {
      let key = chunkKeys[i];
      let chunk = this.chunks[key];
      chunk.detach();
      this.cache.chunks[key] = chunk;
      delete this.chunks[key];
    }

    this.lastPos.copy(this.position);
  }
}

export default ChunkManager;
