import * as THREE from 'three';
import ObjectTypes from './objectTypes';


class Items {
  constructor() {
    this.objects = new THREE.Group();
    this.intersected = null;

    let geometry = new THREE.SphereBufferGeometry();
    let material = new THREE.MeshPhongMaterial({color: 0xffffff, flatShading: true});
    let sphere = new THREE.Mesh(geometry, material);
    sphere.userData.url = 'https://www.youtube.com/embed/axhCdim2njc';
    sphere.userData.type = ObjectTypes.ITEM;
    sphere.position.set(180, -11, 105);
    this.objects.add(sphere);
  }

  mouseOver(object) {
    if (this.intersected !== object) {
      if (this.intersected) this.intersected.material.color.setHex(0xffffff);

      this.intersected = object;
      this.intersected.material.color.setHex(0xff3300);
    }
  }

  mouseOut() {
    if (this.intersected) this.intersected.material.color.setHex(0xffffff);
    this.intersected = null;
  }

  mouseDown(object) {
    window.open(object.userData.url);
  }
}

export default Items;
