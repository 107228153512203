import * as THREE from 'three';


class Sound {
  constructor(listener) {
    const biquadFilter = listener.context.createBiquadFilter();
    biquadFilter.type = 'lowpass';
    biquadFilter.frequency.value = 500;

    this.breakBlockSound = new THREE.Audio(listener);
    this.audioLoader = new THREE.AudioLoader();
    this.audioLoader.load('sounds/break_block.mp3', (buffer) => {
      this.breakBlockSound.setBuffer(buffer);
      this.breakBlockSound.setFilter(biquadFilter);
    });

    this.createBlockSound = new THREE.Audio(listener);
    this.audioLoader = new THREE.AudioLoader();
    this.audioLoader.load('sounds/create_block.mp3', (buffer) => {
      this.createBlockSound.setBuffer(buffer);
      this.createBlockSound.setFilter(biquadFilter);
    });
  }

  breakBlock() {
    if (this.breakBlockSound.isPlaying) this.breakBlockSound.stop();
    this.breakBlockSound.play();
  }

  createBlock() {
    if (this.createBlockSound.isPlaying) this.createBlockSound.stop();
    this.createBlockSound.play();
  }
}

export default Sound;
