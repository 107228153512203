import * as THREE from 'three';
import VirtualJoystick from './virtualJoystick';


export default class Joysticks {

  constructor(camera, controls, onDown) {

    this._camera = camera;
    this._controls = controls;
    this._onDown = onDown;

  }

  /**
   * 回転ジョイスティック
   */
  virtualJoystickRotate(container) {

    let joystick = new VirtualJoystick({
      container: container,
      mouseSupport: false,
      strokeStyle: '#FDE74C'
    });

    const PI_2 = Math.PI / 2;
    let euler = new THREE.Euler(0, 0, 0, 'YXZ');
    let lastX = 0;
    let lastY = 0;
    let lastTouch = null;
    let isTouching = false;
    let isMoved = false;
    let removeAction = null;

    /**
     * スクリーンにタッチしたときの処理
     */
    joystick.addEventListener('touchStart', (event) => {
      isTouching = true;
      isMoved = false;

      lastX = joystick.deltaX();
      lastY = joystick.deltaY();
      lastTouch = event;

      removeAction = setTimeout(() => {
        if (isTouching && !isMoved) {
          // ボクセルの削除
          // event.button = 0;
          // this._onDown(event);
        }
      }, 1500);
    });

    /**
     * タッチしたまま移動したときの処理
     */
    joystick.addEventListener('touchMove', (event) => {
      isMoved = true;

      // 視点回転
      euler.setFromQuaternion(this._camera.quaternion);
      euler.y -= (joystick.deltaX() - lastX) * 0.008;
      euler.x -= (joystick.deltaY() - lastY) * 0.008;
      euler.x = Math.max(-PI_2, Math.min(PI_2, euler.x));
      this._camera.quaternion.setFromEuler(euler);

      // Update last x and y
      lastX = joystick.deltaX();
      lastY = joystick.deltaY();
    });

    /**
     * スクリーンから指を離したときの処理
     */
    joystick.addEventListener('touchEnd', (event) => {
      isTouching = false;

      if (removeAction) {
        clearTimeout(removeAction);
        removeAction = null;
      }

      if (lastTouch) {
        const time = event.timeStamp - lastTouch.timeStamp;

        if (time < 120 && !isMoved) {
          this._controls.jump();
          // ボクセルの追加
          // event.button = 2;
          // this._onDown(event);
        }
      }
    });

  }

  /**
   * 移動ジョイスティック
   */
  virtualJoystickMove(container) {

    let joystick = new VirtualJoystick({
      container: container,
      strokeStyle: 'white',
      mouseSupport: false,
      stationaryBase: true,
      limitStickTravel: true,
      baseX: 120,
      baseY: 135
    });

    let lastTouch = null;
    let isMoved = false;

    /**
     * スクリーンにタッチしたときの処理
     */
    joystick.addEventListener('touchStart', (event) => {
      isMoved = false;
      lastTouch = event;
    });

    /**
     * タッチしたまま移動したときの処理
     */
    joystick.addEventListener('touchMove', (event) => {
      isMoved = true;

      this._controls.moveF = joystick.up();
      this._controls.moveB = joystick.down();
      this._controls.moveL = joystick.left();
      this._controls.moveR = joystick.right();
    });

    /**
     * スクリーンから指を離したときの処理
     */
    joystick.addEventListener('touchEnd', (event) => {
      this._controls.moveF = false;
      this._controls.moveB = false;
      this._controls.moveL = false;
      this._controls.moveR = false;

      if (lastTouch) {
        const time = event.timeStamp - lastTouch.timeStamp;

        if (time < 120 && !isMoved) {
          const isRunning = !this._controls.isRunning
          this._controls.isRunning = isRunning;
          joystick.rebuildJoystickBase(isRunning);
        }
      }
    });

  }

}
