import React from 'react';
import { SwatchesPicker } from 'react-color';


class ColorPalette extends React.Component {

  constructor(props) {

    super(props);
    this.wrapper = React.createRef();

  }

  handleChange = (color, event) => {

    this.props.updateBlockColor(color.hex);
    this.props.closeColorPalette();

  }

  handleClick = (event) => {

    if (!this.wrapper.current.contains(event.target)) {
      this.props.closeColorPalette();
    }

  }

  render() {
    return (
      <div
        id="color-palette"
        className={this.props.show ? '' : 'hide'}
        onClick={this.handleClick}>

        <div ref={this.wrapper}>
          <SwatchesPicker
            onChange={this.handleChange}
          />
        </div>

      </div>
    );
  }

}


export { ColorPalette }
